<template>
  <!-- 调查表 -->
  <div class="research">
    <div class="title">CSON「速成班」学员基本情况调查</div>
    <div class="des">
      非常感谢您能够参加CSON入学摸底调查，提供您的基本信息，便于我们在课程服务周期内给您提供更个性化的求职面试等服务。
      <br />
      我们极其重视数据安全，绝不会泄露任何您的隐私信息。
      <br />
      现在我们就马上开始吧！
    </div>
    <div class="form">
      <el-form ref="form" :model="form" label-position="top" :rules="rules">
        <el-form-item label="姓名" prop="studentName">
          <el-input
            placeholder="请输入姓名"
            v-model="form.studentName"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model="form.gender">
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出生年份" prop="birthday">
          <el-date-picker
            v-model="form.birthday"
            type="year"
            value-format="yyyy-MM"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="身份状态" prop="citizenIdentificationStatus">
          <el-select v-model="form.citizenIdentificationStatus">
            <el-option :value="0" label="F1(未毕业)"></el-option>
            <el-option :value="1" label="OPT(已毕业)"></el-option>
            <el-option :value="2" label="OPT extension"></el-option>
            <el-option :value="3" label="H1B"></el-option>
            <el-option :value="4" label="绿卡"></el-option>
            <el-option :value="5" label="公民"></el-option>
            <el-option :value="6" label="其他"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在时区" prop="timeZone">
          <el-select v-model="form.timeZone">
            <el-option :value="0" label="美东时间"></el-option>
            <el-option :value="1" label="美中时间"></el-option>
            <el-option :value="2" label="美西时间"></el-option>
            <el-option :value="3" label="北京时间"></el-option>
            <el-option :value="4" label="其他时间"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="求职状态" prop="identityStatus">
          <el-radio-group v-model="form.identityStatus">
            <el-radio :label="0">学生</el-radio>
            <el-radio :label="1">在职</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.identityStatus === 0">
          <el-form-item label="最高学历" prop="education">
            <el-radio-group v-model="form.education">
              <el-radio :label="0">本科</el-radio>
              <el-radio :label="1">硕士</el-radio>
              <el-radio :label="2">博士</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="入学及毕业时间" prop="admissionTime">
            <el-date-picker
              v-model="form.admissionTime"
              type="monthrange"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="就读院校" prop="school">
            <el-autocomplete
              v-model="form.school"
              :fetch-suggestions="querySchoolSearch"
              :trigger-on-focus="false"
              placeholder="请选择学校（国内大学请输入中文，国外输入英文）"
              :popper-append-to-body="false"
              @input="
                (val) => {
                  $forceUpdate();
                  searchSchoolLodash(val);
                }
              "
              style="width: 100%"
              @select="selectSchool"
            >
              <template slot-scope="{ item }">
                {{ item.name }}
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="所学专业（转专业请具体说明）" prop="profession">
            <el-input
              placeholder="请输入所学专业"
              v-model="form.profession"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else-if="form.identityStatus === 1">
          <el-form-item label="是否转岗" prop="jobTransfer">
            <el-radio-group v-model="form.jobTransfer">
              <el-radio :label="0">是，转岗CS相关工作</el-radio>
              <el-radio :label="1">否，CS相关工作跳槽</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="就读院校" prop="school">
            <el-autocomplete
              v-model="form.school"
              :fetch-suggestions="querySchoolSearch"
              :trigger-on-focus="false"
              placeholder="请选择学校（国内大学请输入中文，国外输入英文）"
              :popper-append-to-body="false"
              @input="
                (val) => {
                  $forceUpdate();
                  searchSchoolLodash(val);
                }
              "
              style="width: 100%"
              @select="selectSchool"
            >
              <template slot-scope="{ item }">
                {{ item.name }}
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="公司全称" prop="company">
            <el-input
              placeholder="请输入公司全称"
              v-model="form.company"
            ></el-input>
          </el-form-item>
          <el-form-item label="工作年限（年）" prop="workExperience">
            <el-input
              placeholder="请输入工作年限"
              type="number"
              v-model="form.workExperience"
            ></el-input>
          </el-form-item>
          <el-form-item label="工作状态" prop="workStatus">
            <el-select v-model="form.workStatus" placeholder="请选择工作状态">
              <el-option :value="0" label="ICC"></el-option>
              <el-option :value="1" label="合同工"></el-option>
              <el-option :value="2" label="Startup公司（1-10人）"></el-option>
              <el-option :value="3" label="中型公司（100-1000人）"></el-option>
              <el-option :value="4" label="大型公司"></el-option>
            </el-select>
          </el-form-item>
        </template>

        <el-form-item label="求职目标" prop="jobSearchDirection">
          <el-select v-model="form.jobSearchDirection">
            <el-option :value="0" label="无工作经验，找实习"></el-option>
            <el-option :value="1" label="无工作经验，找全职"></el-option>
            <el-option :value="2" label="有实习/全职经验，找实习"></el-option>
            <el-option :value="3" label="有实习/全职经验，找全职"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目前基础" prop="admissionLevel">
          <el-select v-model="form.admissionLevel">
            <el-option :value="0" label="零基础，Java都不会"></el-option>
            <el-option :value="1" label="零基础，语言会"></el-option>
            <el-option :value="2" label="仅学过数据结构"></el-option>
            <el-option :value="3" label="学过部分CS基础课程"></el-option>
            <el-option
              value="4"
              label="科班出身，学过CS体系所有课程"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="刷题水平" prop="admissionQuestions">
          <el-input
            type="number"
            v-model="form.admissionQuestions"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="简历" prop="cv">
          <el-upload
            ref="upload"
            :on-success="handlePDFSuccess"
            :limit="1"
            :before-upload="beforeUpload"
            :on-remove="removePDFFile"
            :action="uploadUrl"
            class="upload-demo"
            accept=".pdf,.PDF"
            style="display: inline-block; width: 300px"
            :data="{ userId: userInfo && userInfo.id }"
            :headers="{ 'X-Access-Token': token }"
          >
            <el-button icon="el-icon-paperclip" size="small" type="primary"
              >上传简历
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="自我情况（介绍个人专属情况）" prop="introduction">
          <el-input
            type="textarea"
            v-model="form.introduction"
            :autosize="{ minRows: 3, maxRows: 40 }"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center">
        <el-button
          type="primary"
          style="margin: 0 auto"
          :disabled="buttonDisabled"
          @click="submitForm"
          >完成</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getSchools } from "@/service/company";
import { submitResearch, getDoResearch } from "@/service/index";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "Research",
  data() {
    var validateCv = (rule, value, callback) => {
      if (this.form.cv == "" || !this.form.cv) {
        callback(new Error("请上传简历!"));
      } else {
        callback();
      }
    };
    return {
      form: {},
      schools: [],
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/uploadResume",
      rules: {
        studentName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        birthday: [
          { required: true, message: "请选择出生年份", trigger: "change" },
        ],
        citizenIdentificationStatus: [
          { required: true, message: "请选择身份状态", trigger: "change" },
        ],
        timeZone: [
          { required: true, message: "请选择所在时区", trigger: "change" },
        ],
        identityStatus: [
          { required: true, message: "请选择求职状态", trigger: "change" },
        ],
        education: [
          {
            required: () => {
              return this.form.identityStatus == "0";
            },
            message: "请选择最高学历",
            trigger: "change",
          },
        ],
        admissionTime: [
          {
            required: () => {
              return this.form.identityStatus == "0";
            },
            message: "请选择入学及毕业时间",
            trigger: "change",
          },
        ],
        school: [
          {
            required: true,
            message: "请填写毕业院校",
            trigger: "blur",
          },
        ],
        profession: [
          {
            required: () => {
              return this.form.identityStatus == "0";
            },
            message: "请填写所学专业",
            trigger: "blur",
          },
        ],
        jobSearchDirection: [
          {
            required: true,
            message: "请选择求职目标",
            trigger: "change",
          },
        ],
        admissionLevel: [
          {
            required: true,
            message: "请选择目前基础",
            trigger: "change",
          },
        ],
        admissionQuestions: [
          {
            required: true,
            message: "请输入刷题水平",
            trigger: "blur",
          },
        ],
        cv: [
          {
            required: true,
            validator: validateCv,
            trigger: "change",
          },
        ],
        introduction: [
          {
            required: true,
            message: "请输入自我情况",
            trigger: "blur",
          },
        ],
        jobTransfer: [
          {
            required: () => {
              return this.form.identityStatus == "1";
            },
            message: "请选择转岗情况",
            trigger: "change",
          },
        ],
        company: [
          {
            required: () => {
              return this.form.identityStatus == "1";
            },
            message: "请填写公司全称",
            trigger: "blur",
          },
        ],
        workExperience: [
          {
            required: () => {
              return this.form.identityStatus == "1";
            },
            message: "请填写工作年限",
            trigger: "blur",
          },
        ],
        workStatus: [
          {
            required: () => {
              return this.form.identityStatus == "1";
            },
            message: "请选择工作状态",
            trigger: "change",
          },
        ],
      },
      buttonDisabled: false,
    };
  },
  methods: {
    querySchoolSearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.schools);
      }, 1000);
    },
    searchSchoolLodash: _.debounce(function (val) {
      this.searchSchools(val);
    }, 1000),
    searchSchools(val) {
      getSchools(val).then((res) => {
        if (res.success) {
          this.schools = res.result;
        }
      });
    },
    selectSchool(option) {
      this.form.school = option.name;
    },
    handlePDFSuccess(res, file) {
      this.form.cv = res.result.objectKey;
    },
    removePDFFile() {
      this.form.cv = "";
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt10M;
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          submitResearch(this.form).then((res) => {
            if (res.success) {
              this.buttonDisabled = true;
              this.$message.success("提交成功，感谢您参与调查！");
              setTimeout(() => {
                this.$router.push("/");
              }, 200);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    // getDoResearch().then((res) => {
    //   if (res.success) {
    //     if (res.result) {
    //       this.$router.push("/");
    //       this.$message.info("您已填写过该调查！");
    //     }
    //   }
    // });
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style scoped lang="scss">
.title {
  border-top: 44px solid rgb(40, 117, 232);
  padding-top: 12px;
  text-align: left;
  font-family: inherit;
  font-size: 20px;
  font-weight: normal;
  color: rgb(34, 34, 34);
  padding: 20px;
}
.des {
  font-size: 14px;
  color: #666;
  padding: 0 20px;
}
.research {
  width: 800px;
  margin: 0 auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.form {
  padding: 0 20px;
  padding-bottom: 40px;
}
</style>